import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import ReactDOM from "react-dom/client";

function App() {
  const [name, setName] = useState("myreactapp");
  return (
    <div className="w-form">
      <form id="email-form" name="email-form" method="get">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          placeholder=""
          id="name"
          className="w-input"
          maxLength="256"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="">{name}</label>
        <input type="submit" value="Submit" className="w-button" />
      </form>
    </div>
  );
}

export default App;
